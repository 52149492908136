<template>
  <div class="inside-page-content">
    <div style="background-color:#fff;padding: 20px;">
      <div class="notice-title h1">{{ detailData.title }}</div>
      <div class="h4 time">【时间】：{{ detailData.createTime }}</div>
      <div class="notice-content">
        <div class="image">
          <el-carousel :interval="2000" height="300px">
            <el-carousel-item v-for="item in detailData.images" :key="item">
              <img :src="item" style="height:300px;object-fit: cover;width: 100%;" />
            </el-carousel-item>
          </el-carousel>
        </div>

        <div class="right">
          <div class="row">
            <div style="color: #FC6035;" class="h1">{{detailData.monthlyRent}}</div>
            <div class="h4">元/月</div>
            <div style="margin-left: 20px;">
              {{areaDayPrice}}元/m²/天
              <span v-if="detailData.isNegotiated=='1'">（价格可面议）</span>
            </div>
          </div>
          <div class="row" style="margin-bottom: 20px;">
            <div class="unit-item value-title h1">{{detailData.floorSpace}}m²</div>
            <div class="unit-item value-title h1">{{ detailData.workshopType=='1'?'独院厂房':'其他' }}</div>
            <div class="unit-item value-title h1">{{detailData.rentSpace}}m</div>
          </div>
          <div class="row">
            <div class="unit-item">建筑面积</div>
            <div class="unit-item">厂房类型</div>
            <div class="unit-item">起租面积</div>
          </div>
          <div class="row">联 系 人：{{detailData.rentSpace}}</div>
          <div class="row">联系电话：{{detailData.contactPhone}}</div>
          <div class="row">商家地址：{{ detailData.businessAddress }}</div>
        </div>
      </div>
      <blue-tag title="基本信息"></blue-tag>
      <div class="sub-info-wrapper">
        <div class="sub-info-item-wrapper">
          <div class="info-title">类型</div>
          <div class="info-content">{{ detailData.rentType=='1'?'厂房出租':'其他' }}</div>
        </div>
        <div class="sub-info-item-wrapper">
          <div class="info-title">可办环评</div>
          <div class="info-content">{{ detailData.isEia=='1'?'是':'否' }}</div>
        </div>
        <div class="sub-info-item-wrapper">
          <div class="info-title">押付</div>
          <div class="info-content">{{ detailData.pledgeType=='1'?'押1付3':'押2付6' }}</div>
        </div>
        <div class="sub-info-item-wrapper">
          <div class="info-title">起租期</div>
          <div class="info-content">{{detailData.rentTimeLimit}}个月</div>
        </div>
        <div class="sub-info-item-wrapper">
          <div class="info-title">租赁方式</div>
          <div class="info-content">{{ detailData.rentMethod=='1'?'整租':'其他' }}</div>
        </div>
        <div class="sub-info-item-wrapper">
          <div class="info-title">地址</div>
          <div class="info-content">{{ detailData.businessAddress }}</div>
        </div>
      </div>
      <blue-tag title="内部详情"></blue-tag>
      <div class="sub-info-wrapper">
        <div class="sub-info-item-wrapper">
          <div class="info-title">楼层</div>
          <div class="info-content">{{ detailData.floor=='1'?'单层':'多层' }}</div>
        </div>
        <div class="sub-info-item-wrapper">
          <div class="info-title">首层层高</div>
          <div class="info-content">{{detailData.firstFloorHeight}}m</div>
        </div>
        <div class="sub-info-item-wrapper">
          <div class="info-title">厂房新旧</div>
          <div class="info-content">{{ detailData.isNew=='1'?'全新':'八成新' }}</div>
        </div>
        <div class="sub-info-item-wrapper">
          <div class="info-title">厂房结构</div>
          <div class="info-content">{{ detailData.workshopStructure }}</div>
        </div>
        <div class="sub-info-item-wrapper">
          <div class="info-title">楼板承重</div>
          <div class="info-content">{{detailData.bearing}}吨</div>
        </div>
        <div class="sub-info-item-wrapper">
          <div class="info-title">消防备案</div>
          <div class="info-content">{{ detailData.isFireControl=='1'?'是':'否' }}</div>
        </div>
        <div class="sub-info-item-wrapper">
          <div class="info-title">厂房特色</div>
          <div class="info-content">{{ detailData.workshopFeature }}</div>
        </div>
      </div>
      <blue-tag title="描述"></blue-tag>
      <div class="sub-info-wrapper">
        <div class="sub-info-item-wrapper" style="width: unset;">
          <div class="info-title">房源亮点</div>
          <div class="info-content">{{ detailData.highlights }}</div>
        </div>
      </div>
      <blue-tag title="配套"></blue-tag>
      <div class="sub-info-wrapper">
        <div
          :class="['img-tag-item',detailData[item.key]=='1'?'img-tag-item-delete':'']"
          v-for="item in icons"
          :key="item.key"
        >
          <img class="tag-image" :src="item.img" />
          <div class="tag-title">{{ item.title }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import blueTag from './components/blue-tag-section-title.vue'

export default {
  name: 'Home',
  components: {
    blueTag
  },
  data() {
    return {
      notice_id: null,
      detailData: {},
      icons: [
        {
          'key': 'isWifi',
          'img': require('@/static/images/soli-icon/wifi.png'),
          'title': '宽带'
        },
        {
          'key': 'isTv',
          'img': require('@/static/images/soli-icon/电视.png'),
          'title': '有线电视'
        },
        {
          'key': 'isWater',
          'img': require('@/static/images/soli-icon/水表.png'),
          'title': '水表'
        },
        {
          'key': 'isElectricity',
          'img': require('@/static/images/soli-icon/灯泡.png'),
          'title': '电'
        },
        {
          'key': 'isPhone',
          'img': require('@/static/images/soli-icon/电话.png'),
          'title': '电话'
        },
        {
          'key': 'isAir',
          'img': require('@/static/images/soli-icon/空调.png'),
          'title': '空调'
        },
        {
          'key': 'isHeating',
          'img': require('@/static/images/soli-icon/暖气.png'),
          'title': '暖气'
        },
        {
          'key': 'isGas',
          'img': require('@/static/images/soli-icon/煤气.png'),
          'title': '煤气'
        },
        {
          'key': 'isElevator',
          'img': require('@/static/images/soli-icon/电梯.png'),
          'title': '电梯'
        },
        {
          'key': 'isOffice',
          'img': require('@/static/images/soli-icon/办公室.png'),
          'title': '办公室'
        },
        {
          'key': 'isParking',
          'img': require('@/static/images/soli-icon/停车场.png'),
          'title': '停车场'
        },
        {
          'key': 'isDormitory',
          'img': require('@/static/images/soli-icon/宿舍.png'),
          'title': '宿舍'
        },
        {
          'key': 'isCanteen',
          'img': require('@/static/images/soli-icon/食堂.png'),
          'title': '食堂'
        },
        {
          'key': 'isPlatform',
          'img': require('@/static/images/soli-icon/月台.png'),
          'title': '月台'
        },
      ]
    }
  },
  computed: {
    areaDayPrice() {
      var monthlyRent = this.detailData?.monthlyRent ?? 0
      var floorSpace = this.detailData?.floorSpace ?? 1
      return monthlyRent / floorSpace / 30
    },
  },
  created(options) {
    console.log('options = ', options)
    console.log('this.$route.query = ', this.$route.query)
    const notice_id = this.$route.query?.id
    if (notice_id) {
      this.notice_id = notice_id
      this.$api.solicitationCloud.businessWorkshopDetail({ 'id': this.notice_id }).then((res) => {
        const data = res.data.data
        if (data) {
          this.detailData = data;
        }
      })
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>
<style scoped lang="less">
.notice-title {
  margin-top: 35px;
  font-weight: 700;
}

.time {
  // text-align: center;
  margin: 10px 0;
  padding-bottom: 30px;
  border-bottom: 2px dashed #cccccc;
}

.notice-content {
  margin-top: 30px;
  height: 300px;
  display: flex;
  align-items: stretch;
  color: #868484;

  .image {
    flex: 1;
    min-width: 200px;
    height: 100%;
  }

  .right {
    flex: 2;
    min-width: 300px;
    margin-left: 40px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;

    .row {
      display: flex;
      align-items: flex-end;
      margin-bottom: 25px;

      .unit-item {
        width: 200px;
      }

      .value-title {
        font-weight: 500;
      }
    }
  }
}

.sub-info-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-left: 50px;

  .sub-info-item-wrapper {
    display: flex;
    width: 40%;
    margin-bottom: 10px;
    font-size: 16px;

    .info-title {
      color: #818181;
      min-width: 100px;
    }

    .info-content {
    }
  }

  .img-tag-item {
    width: 14%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .tag-image {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      object-fit: contain;
    }

    .tag-title {
    }
  }
  .img-tag-item-delete {
    opacity: 0.5;
    text-decoration: line-through;
  }
}
</style>
